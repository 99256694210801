<template>
  <el-dialog :visible.sync="dialogVisible" custom-class="custom-dialog emailSubscriptionSet"
    :modal-append-to-body="false">
    <div class="head" slot="title">
      <h2 class="title">{{ $t("通知设定") }}</h2>
    </div>
    <div class="body">
      <el-form ref="form">
        <el-form-item>
          <div class="form-item-content">
            <el-switch class="switch el-switch-large" v-model="form.auto_mail_notify_sta"/>
            <div>{{ $t("愿意收到通知邮件") }}</div>
          </div>
        </el-form-item>
        <el-form-item>
          <div class="form-item-content">
            <el-switch class="switch el-switch-large" v-model="form.admin_mail_notify_sta"/>
            <div>{{ $t("愿意收到营销邮件") }}</div>
          </div>
        </el-form-item>
        <el-button class="button_fullwidth button button_blue" :loading="loading" @click="change">{{ $t("变更") }}</el-button>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
import { my_check_email_notify_sta_api } from "@/api/user"
export default {
  data() {
    return {
      dialogVisible: false,
      form: {
        auto_mail_notify_sta: true,
        admin_mail_notify_sta: true,
      },
      loading:false
    }
  },
  methods: {
    show() {
      this.dialogVisible = true
      this.form.auto_mail_notify_sta = this.$store.state.userInfo.auto_mail_notify_sta == 1
      this.form.admin_mail_notify_sta = this.$store.state.userInfo.admin_mail_notify_sta == 1
    },
    async change() {
      this.loading = true
      const [res_admin_mail_notify_sta,res_auto_mail_notify_sta] = await Promise.all([
        my_check_email_notify_sta_api({ type:"admin_mail_notify_sta", status: this.form.admin_mail_notify_sta ? 1 : 2}),
        my_check_email_notify_sta_api({ type:"auto_mail_notify_sta", status: this.form.auto_mail_notify_sta ? 1 : 2})
      ])
      this.loading = false
      if(res_admin_mail_notify_sta.data.code!==1){
        this.errorTips(res_admin_mail_notify_sta.data.msg)
      }else if(res_auto_mail_notify_sta.data.code!==1){
        this.errorTips(res_auto_mail_notify_sta.data.msg)
      }else{
        this.successTips(this.$t("设定成功"));
        this.dialogVisible = false
      }
    }
  },
}
</script>
<style scoped>
:deep(.emailSubscriptionSet) {
  max-width: 360px !important;
}

.head {
  position: relative;
  padding-top: 10px;
}

.title {
  font-size: 22px;
  margin-bottom: -10px;
}

.desc {
  display: block;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #8e939e;
  text-align: center;
}


.form-item-content {
  display: flex;
  align-items: center;
  color: #FFF;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.6;
  margin-bottom: 14px;
}

.switch{
  margin-right: 12px;
}
.button{
  margin-top: 40px;
}
</style>
