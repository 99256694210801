<template>
  <moveComponents class="wufu-box" :magnetic="true" :limitPosition="{left:20,right:20,bottom:90,top:140}" v-if="$t('menu_five_bessings') != 'menu_five_bessings' && $store.state.fiveBessings.fiveBessData.id">
    <div @click="$router.push('/five-bessings')">
      <img src="@/assets/images/wufu-fd.png"
        :class="['wufu-icon', $store.state.fiveBessings.fiveBessData.num ? 'wufu-icon-an' : '']" :alt="$t('集五福')"/>
    </div>
  </moveComponents>
</template>

<script>
import moveComponents from "@/components/moveComponent.vue"
export default {
 components:{moveComponents}
}
</script>

<style scoped lang="less">
.wufu-box {
  position: fixed;
  width: 50px;
  height: 50px;
  z-index: 999;
  @media (min-width: 769px) {
    bottom: 160px !important;
    right: 30px !important;
  }
  @media (max-width: 768px) {
    bottom: 150px;
    bottom: calc(150px + constant(safe-area-inset-bottom));
    bottom: calc(150px + env(safe-area-inset-bottom));
    right: 20px;
  }

  .wufu-icon {
    width: 100%;
    height: 100%;
    display: block;
  }
}

.wufu-icon-an {
  animation: wufu-icon1 1s infinite, wufu-icon2 .3s infinite;
  border-radius: 50%;
}

@keyframes wufu-icon1 {
  0% {
    box-shadow: 0 0 0 #9663d1;
  }

  50% {
    box-shadow: 0 0 30px #9663d1;
  }

  100% {
    box-shadow: 0 0 0 #9663d1;
  }
}

@keyframes wufu-icon2 {
  0% {
    transform: rotate(5deg)
  }

  50% {
    transform: rotate(-5deg)
  }

  100% {
    transform: rotate(5deg)
  }
}
</style>