<template>
  <templateComponent>
    <div class="pachinko">
      <div class="pachinko-header">
        <div class="pachinko-header-content">
          <div class="pachinko-subtitle">{{ $t("欢迎来到") }}</div>
          <div class="pachinko-title">{{ $t("柏青哥") }}</div>
        </div>
        <img src="@/assets/images/pachinko.png" class="pachinko-cover" :alt="$t('柏青哥')">
      </div>
      <gameItem class="pachinko-game-item" :beforGetGameGetUrl="()=>openUrl(item.href)" v-for="item in $store.state.menuList.menuList?.parzuru_bqg_hall" :key="item.href" type="hot">
        <cyb-img slot="cover" :src="item.logo" style="width:100%;height: 100%;" fit="cover" :alt="item.game_name"></cyb-img>            
      </gameItem>
    </div>
  </templateComponent>
</template>

<script>
import templateComponent from '@/components/template.vue';
import gameItem from "@/components/games/gameItem.vue"
export default {
  components: { templateComponent , gameItem }
}
</script>

<style scoped lang="less" src="@/assets/css/pachinko.less"></style>