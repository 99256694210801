<template>
  <div class="root">
    <myTabs :list="type_list.map((item) => item.name)" @change="tabsChange" class="tabs" :showIcon="false">
      <template v-slot:icon="{ i }">
        <img :src="type_list[i].icon" class="tabs-icon" />
      </template>
    </myTabs>

    <picture class="task-banner" v-if="$t('task_banner') != 'task_banner'">
      <source media="(min-width: 768px)" :srcset="$t('task_banner')">
      <img :src="$t('task_banner_mobile')">
    </picture>

    <!-- head -->
    <div class="task-head" v-if="!stageData">
      <div class="task-head-left">
        <img :src="$store.state.userInfo?.head_img" class="task-head-avatar">
        <div class="task-head-integral">
          <img :src="require('@/assets/images/integral.png')" class="integral-icon">
          <span class="task-head-title">{{ $t("积分") }}</span>
          <span class="integral-num">{{ numFormat(lottery_config?.integral || 0) }}</span>
        </div>
      </div>
      <div class="task-head-right">
        <el-button class="button_red"
          :disabled="!(lottery_config?.integral >= lottery_config?.required_integral || lottery_config?.free_count > 0)"
          @click="$router.replace('?dialog=wheel')">
          <img :src="require('@/assets/images/integral.png')" class="integral-icon">
          <span>{{ $t("转盘") }}</span>
          <i class="el-icon el-icon-arrow-right"></i>
        </el-button>
        <el-button class="button_red" v-if="$store.state.fiveBessings.fiveBessData.id"
          :disabled="!($store.state.fiveBessings.fiveBessData.num > 0)" @click="$router.push('/five-bessings')">
          <img :src="require('@/assets/images/fiveB.png')" class="integral-icon">
          <span>{{ $t("刮刮卡") }}</span>
          <i class="el-icon el-icon-arrow-right"></i>
        </el-button>
      </div>
    </div>

    <div class="task-container" v-loading="loading">
      <div class="task-wapper">
        <div class="task-left" v-if="stageData">
          <div class="task-title">{{ $t("刷新倒计时") }} {{ timeEnd(stageData.end_time) }}</div>
          <div class="task-subtitle">
            <span>{{ $t("当前活跃值") }}</span>
            <img class="bi-icon" :src="require('@/assets/images/flame_animation.gif')" alt="">
            <span>{{ stageData.activity }}</span>
          </div>
          <swiper class="task-swiper" :options="swiperOptionThumbs" ref="task-swiper">
            <swiper-slide class="task-slide" v-for="item, index in stageData.reg_json" :key="index">
              <TaskBoxItem @tabItem="stage_receive(item)" :loading="item.loading" :data="item"
                :progress="progress(stageData.activity, stageData.reg_json, index)"></TaskBoxItem>
            </swiper-slide>
          </swiper>
        </div>
        <div :class="['right-wapper', !stageData ? 'right-wapper-full' : '']">
          <div :class="['task-right', !stageData ? 'task-right-full' : '']" v-if="initList.length">
            <!-- 任务 -->
            <div class="task-item"
              :class="{ 'lock': $store.state.userInfo?.level_id !== item.receive_level_id && item.receive_level_id > 0 }"
              v-for="item in initList" :key="item.id">
              <img class="lock-icon" src="@/assets/images/lock.png" alt=""
                v-if="$store.state.userInfo?.level_id !== item.receive_level_id && item.receive_level_id > 0">
              <div style="display: flex;">
                <div class="itme-content">
                  <div class="itme-name">
                    <span>{{ item.title }}</span>
                    <img src="@/assets/images/task-wenhao.png" class="item-wh" @click="showInfo(item)">
                  </div>
                  <div class="itme-desc">{{ item.introduce }}</div>
                  <div class="item-progress-box">
                    <div class="item-progress-wapper">
                      <div class="item-progress-content" :style="`width:${item.finish_num / item.task_num * 100}%`">
                      </div>
                    </div>
                    <div class="item-progress-txt">{{ item.finish_num }}/{{ item.task_num }}</div>
                  </div>
                </div>
                <div class="item-btn-wapper">
                  <!-- 五福次数奖励 -->
                  <div class="item-bi-wapper" v-if="item.reward_type == 2">
                    <img class="bi-icon" :src="require('@/assets/images/fiveB.png')">
                    <span>x{{ item.w_count }}</span>
                  </div>
                  <!-- 免费游戏 -->
                  <div class="item-bi-wapper" v-else-if="item.reward_type == 3">
                    <img class="bi-icon" :src="require('@/assets/images/free-game.png')">
                    <span>x{{ item.free_game.round }}</span>
                  </div>

                  <!-- 积分和活跃值 -->
                  <div class="item-bi-wapper" v-else>
                    <img class="bi-icon" :src="require('@/assets/images/flame_animation.gif')" v-if="item.activity > 0">
                    <img class="bi-icon" :src="require('@/assets/images/vnd.png')" v-else-if="item.reward_type == 0">
                    <img class="bi-icon" :src="require('@/assets/images/integral.png')"
                      v-else-if="item.reward_type == 1">
                    <span>x{{ item.activity > 0 ? item.activity : item.amount_or_integral }}</span>
                  </div>

                </div>
              </div>
              <el-button class="item-btn"
                v-if="$store.state.userInfo?.level_id !== item.receive_level_id && item.receive_level_id > 0">{{
                  $t(item.receive_level_name) }}{{ $t('解锁') }}</el-button>
              <el-button v-else class="item-btn button_blue" @click="receive(item)"
                :disabled="!(item.is_receive == 0 && item.is_finsh == 1)" :loading="item.loading">{{
                  $t(itemBtnTxt(item)) }}</el-button>
            </div>
          </div>
          <div class="none-wapper" v-else>
              <el-empty :image="$t('task_lock_img')" :description="$t('近日公开')" />
          </div>
        </div>
      </div>
    </div>
    <TaskInfo ref="taskInfo"></TaskInfo>
  </div>
</template>

<script>
import TaskBoxItem from "./taskBoxItem.vue";
import TaskInfo from "./taskInfo.vue";
import { task_list_api, task_receive_api, task_stage_receive_api } from "@/api/task.js";
import myTabs from "../../components/myTabs.vue";
import socketRouter from '@/utils/SocketRouter'
import { lottery_config_api } from "@/api/lottery";

export default {
  data() {
    return {
      timeId: null,
      curTime: new Date().getTime(),
      list: [],
      type_list: [],
      current_symbol: "",
      cur: 0,
      loading: true,
      lottery_config: null,
      swiperOptionThumbs: {
        loop: false,
        autoplay: false,
        spaceBetween: 0,
        slidesPerView: 4,
        breakpoints: {
          540: { slidesPerView: 3 },
          768: { slidesPerView: 4 },
          1024: { slidesPerView: 3 }
        },
      },
    };
  },
  components: { TaskBoxItem, TaskInfo, myTabs },
  async mounted() {
    // 注册长连接监听
    socketRouter.addRouter("task", this.getList)
    this.loading = true
    await Promise.all([
      this.getList(),
      this.get_lottery_config(),
      this.$store.dispatch("fiveBessings/updateFiveBessData")
    ])
    this.loading = false
    this.timeId = setInterval(() => {
      this.curTime = new Date().getTime()
    }, 1000)
  },
  computed: {
    stageData() {
      if (this.list.reg_json) {
        return this.list
      } else {
        return null
      }
    },
    initList() {
      return this.list.task_list || this.list
    }
  },
  methods: {
    itemBtnTxt(item) {
      if (item.is_finsh == 0) {
        return "未完成"
      }
      if (item.is_receive == 1) {
        return "已领取"
      }
      return "领取"
    },
    timeEnd(timeStamp) {
      const time = timeStamp * 1000 - this.curTime
      const d = parseInt(time / 86400000) || 0;
      const h = parseInt(time / 3600000) % 24 || 0;
      const m = parseInt(time / 60000) % 60 || 0;
      const s = parseInt(time / 1000) % 60 || 0;
      return `${d.toString().padStart(2, 0)}D ${h.toString().padStart(2, 0)}:${m.toString().padStart(2, 0)}:${s.toString().padStart(2, 0)}`
    },
    progress(allNum, list, i) {
      const currentMax = list[i].max_activity;
      const nextMax = list[i + 1] ? list[i + 1].max_activity : null;
      const lastMax = list[i - 1] ? list[i - 1].max_activity : 0;

      // 防止 allNum 超出上下限范围
      if (allNum < lastMax) {
        return 0; // 在上一阶段未满
      }

      if (allNum >= currentMax) {
        // 当前阶段进度条已满
        if (!nextMax) {
          // 没有下一阶段的情况
          return 100;
        }
        if (allNum >= nextMax) {
          return 100; // 超过下一阶段，当前阶段仍然为满
        }
        // 平滑分配到下一阶段
        return 50 + (Math.min(50, 100 * (allNum - currentMax) / (nextMax - currentMax)));
      } else {
        if(i==0){
          return Math.min(50, 50 * (allNum - lastMax) / (currentMax - lastMax));
        } else{
          return Math.max(0, 100 * (allNum - lastMax) / (currentMax - lastMax) - 50);
        }       
        // 当前阶段未满时，根据比例计算
      }
    },
    showInfo(item) {
      this.$refs.taskInfo.show(item);
    },
    async tabsChange(i) {
      this.cur = i
      this.loading = true
      await this.getList(this.type_list[i].id)
      this.loading = false
    },
    sortTask(list) {
      return list.sort((a, b) => {
        const getPriority = (item) => {
          if (item.receive_level_id == 0 || item.level_id == item.receive_level_id) {
            if (item.is_receive == 1) return -1
            if (item.is_finsh == 0) return -2
            return -3;
          }
          return 1;
        };
        return getPriority(a) - getPriority(b)
      });
    },

    // 获取任务列表
    async getList(task_type = "") {
      const { data } = await task_list_api({ task_type });
      if (data.code == 1) {
        if (data.data.list.task_list) {
          data.data.list.task_list = this.sortTask(data.data.list.task_list)
          this.list = data.data.list
        } else {
          this.list = this.sortTask(data.data.list)
        }
        this.current_symbol = data.data.current_symbol;
        this.type_list = [
          {
            name: this.$t("全部任务"),
            icon: require("@/assets/images/allA.png"),
            id: "",
          },
          ...data.data.task_type_list,
        ];
      }
    },

    // 领取任务奖励
    async receive(item) {
      item.loading = true
      this.$forceUpdate()
      const { data } = await task_receive_api({ task_id: item.id })
      if (data.code == 1) {
        this.successTips(this.$t('领取成功'))
        await Promise.all([
          this.getList(this.type_list[this.cur].id),
          this.get_lottery_config(),
          this.updateUserInfo(),
          this.$store.dispatch("fiveBessings/updateFiveBessData")
        ])
        // 计算消息
        this.$store.commit("msg/reduce", "task")

      } else {
        this.errorTips(data.msg)
      }
      item.loading = false
      this.$forceUpdate()
    },

    // 领取阶段奖励
    async stage_receive(item) {
      item.loading = true
      this.$forceUpdate()
      const { data } = await task_stage_receive_api({ stage: item.stage, stage_id: this.stageData.id })
      if (data.code == 1) {
        this.successTips(this.$t('领取成功'))
        await Promise.all([
          this.getList(this.type_list[this.cur].id),
          this.get_lottery_config(),
          this.updateUserInfo(),
          this.$store.dispatch("fiveBessings/updateFiveBessData")
        ])
      } else {
        this.errorTips(data.msg)
      }
      item.loading = false
      this.$forceUpdate()
    },

    // 获取转盘数据
    async get_lottery_config() {
      const { data: res } = await lottery_config_api()
      if (res.code == 1) {
        this.lottery_config = res.data
      }
    }
  },
  beforeDestroy() {
    // 消除副作用
    clearInterval(this.timeId)
    socketRouter.removeRouter("task", this.getList)
  }
};
</script>

<style lang="less" scoped src="@/assets/css/taskCenter.less"></style>

<style lang="less" scoped>
::v-deep(.swiper-wrapper) {
  justify-content: center;
}
.none-wapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10vh;
  color: #656c79;
}
</style>
